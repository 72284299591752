import React from "react";

const FallBack = () => {
  return (
    <div>
      <h1>Something went wrong, Should be rectified shortly</h1>
    </div>
  );
};

export default FallBack;
