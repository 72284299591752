import React from "react";
import BigImg from "../../assets/images/hero-1.png";
import BigImgTwo from "../../assets/images/hero-2.png";
import PhoneImg from "../../assets/images/aboutUs-PhoneImg.png";
import OurTeamComponent from "./Team";
import OurAdvisoryTeamComponent from "./Team/advisory";
import "./styles.css";

const Index = () => {
  return (
    <>
      <main>
        <section className="">
          <div className="container-fluid px-0">
            <div className="col-lg-12 px-5">
              <div className="d-flex justify-content-center">
                <div className="col-lg-6 px-0 mt-60">
                  <div className="lh-lg">
                    <h5 className="aboutUs-header text-center lh-lg">
                      Building a community of smart savers with a smart saving
                      culture.
                    </h5>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <div className="col-lg-8 px-0 mt-5">
                  <div className="">
                    <img src={BigImg} className="img-fluid" alt="About Us" />
                  </div>
                  <div className="">
                    <img src={BigImgTwo} className="img-fluid" alt="About Us" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="">
          <div className="container">
            <div className="col-lg-12 mt-60">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="col-lg-6 px-0">
                  <div className="bg-grey about-pp-custom">
                    <h5 className="aboutUs-header">Who We Are</h5>
                    <div className="small-red-line"></div>
                    <p className="mt-5 whoWeAre-p">
                      A company that seeks to provide inclusive wealth
                      management services to segments of the market currently
                      neglected and underserved by the traditional wealth
                      management companies connecting these segments of the
                      market to these services through robust technology
                      infrastructure.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 px-0 mt-5 pt-4">
                  <div>
                    <img src={PhoneImg} className="img-fluid" alt="Phones" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="">
          <div className="container">
            <div className="row mt-60">
              <div className="col-lg-12 pt-5">
                <h5 className="aboutUs-header pb-1">Meet The Team</h5>
                <div className="small-red-line"></div>
                <p className="py-3">
                  The team is composed of seasoned professionals with diverse
                  skills in finance, investment management, banking, software
                  engineering and data sciences to ensure you get the best in
                  class experience.
                </p>
              </div>
            </div>

            <OurTeamComponent />
          </div>
        </section>

        <section className="">
          <div className="container">
            <div className="row mt-60">
              <div className="col-lg-12">
                <h5 className="aboutUs-header pb-1">
                  Advisory Team{" "}
                  <span className="text-dark fs-1">{"{Coming Soon}"}</span>
                </h5>
                <div className="small-red-line"></div>
              </div>
            </div>

            <OurAdvisoryTeamComponent />
          </div>
        </section>
      </main>
    </>
  );
};

export default Index;
