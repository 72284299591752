export const GeneralQuestions = [
  {
    question: "What is investYield?",
    answer1:
      "Welcome to investYield, an urban digital platform that allows you to automate your savings and discover true money happiness. You can save small amounts periodically or save a lump sum one time by committing  to goals and also join interesting savings challenges to achieve financial freedom.",
    answer2:
      "We offer security, speed, flexibility and discipline-instilled financial management tools to achieve your financial goals.",
  },

  {
    question: "What are the Saving features on investYield?",
    answer1:
      "The platform was designed in collaboration with sound financial advisers and behavioural scientists to help identify how best to help people save more securely and efficiently.",
    answer2: "",
  },
  {
    question:
      "There are three main main savings features on investYield, carefully curated to help you to achieve maximum financial wellness:",
    answer1:
      "Automated Goal-based savings plans: Here you will see that we carefully created ready-to-go savings plans to help you achieve specific financial goals. You also have the liberty to create customized plans if you desire. However, note that once plans are created they become locked and cannot be cancelled or terminated until maturity date.",
    answer2:
      "myPurse: Here you will find that we have created mini-digital wallets that help you to categorize your routine expenses into different expense purses. We consider this a powerful budgeting tool as well, because it helps you to easily determine where and how you spend money. We understand you cannot only be saving money, you also need to spend money to take care of important routine expenses. We have also curated some ready-to-go purses that we believe will excite you. Money kept in your purse can be accessed at any time without any breakage penalty.",
    answer3:
      "Stash: We recognise that some of our users might be very busy and not have the time to create savings plans or expense purses, so we came up with a solution called stash. Here, all you need to do is select the amount you want to save and for how long, similar to regular fixed deposits. There is a small breakage fee, when you decide to break your stash before maturity date.",
  },

  {
    question: "How do I start saving on investYield?",
    answer1:
      "This is very easy, Our sign up has been oversimplified, fill a savings behavioral questionnaire that allows us to know you better, select a savings feature that fits your savings objective and get the plan funded. The plan is automatically created for you, you can start saving and enjoying very best possible interest rates.",
  },

  {
    question:
      "Can I transfer money to third party beneficiaries through the App:",
    answer1:
      "We have an interesting peer to peer transfer feature on the app. This allows you to send money to other members on the platform. We added a little spice that also allows you to request for money from other people on the platform as well",
  },
];

export const SafetySecurity = [
  {
    question: "How safe is my financial information on investYield?",
    answer1:
      "The platform was designed and built by a team of experienced bankers, data scientists, behavioral scientists and software engineers. We understand the safety of your financial information is critical. investYield does not store personal financial information, these are retained by our payment partners with PCI-DSS compliance certificate.  Your savings record on our platform is encrypted and secured by 256 bits SSL security encryption to ensure that your information is completely protected.",
  },

  {
    question: "What is OTP?",
    answer1:
      "This is a One Time Password used for initiating transfers and withdrawals on your investYield account.",
    answer2: "",
  },
  {
    question: "2FA Authentication on investYield?",
    answer1:
      "This means there are two layers of security for transactions on your investYield account.",
  },

  {
    question: "Where is investYield's physical office?",
    answer1:
      "Our physical office is located at No1, Adewale Oshin street, Off Chief Collins, Lekki Phase1, Lagos, Nigeria",

    answer2: "Email: support@investYield.ng",
    answer3: "website: https://investyield.ng",
  },

  {
    question: "How safe are my card details?",
    answer1:
      "The security of your personal information is our utmost priority, so we work with a PCI-DSS-compliant payment processors, Paystack to handle all card related transactions and your card information is retained with them.. ",

    answer2:
      "Your card details are extremely safe and are stored where they can never be compromised. ",
  },

  {
    question: "How safe is my saved money?",
    answer1:
      "We are a fully incorporated Company and a fully registered and licensed Multi-Purpose Cooperative Society. We partner with registered commercial banks and other capital market operators licensed by the CBN and SEC.  ",

    answer2:
      "All saved funds are warehoused with and managed through our partner Bank, so your funds are insured by NDIC",
  },
];

export const Savings = [
  {
    question: "Do I need to login every time I want to save?",
    answer1:
      "Our application and in partnership with our payment processors, you can automate a recurring debit, when setting up your savings plan so that on every due date, the allocated amount is taken from your bank and kept in your savings plan with investYield. The process is extremely easy.",
  },

  {
    question: "Can I pause on my savings plan anytime?",
    answer1:
      "Once a savings plan has been initiated, you will be unable to pause it but will have to wait for the maturity date to receive your money and interest. In the event of special emergencies, please reach out to our support teams on support@investyield.ng",
  },
  {
    question: "Can I increase my savings at anytime?",
    answer1:
      "Yes, you can increase your savings anytime through the edit feature or create a new plan for the additional sum you want to save.",
  },

  {
    question:
      "What if I don't have funds in my saved debit card linked account?",
    answer1:
      "You can add a new card or choose another payment method. We will also send you reminders when you have passed a set saving date.",
  },
];

export const BVN = [
  {
    question: "Why do I have to add my BVN?",
    answer1:
      "We are required by regulations to verify your KYC (know your customer), the BVN is for verification of your identity as it is currently a single source of truth for financial institutions. Also, you will be unable to use the peer to peer transfer feature on the app if you have not provided your BVN.",
  },

  {
    question:
      "What if I no longer have access to the phone number linked to my BVN?",
    answer1:
      "An OTP will be sent to the email linked with your BVN as well if you provided one. However, if there are none, then you will need to proceed to your bank and update your BVN so as to enjoy more features on investYield.",
  },
];

export const TargetGroup = [
  {
    question: "What is Target Savings?",
    answer1:
      "Target savings is a plan  set up to achieve a certain goal. You can save for rent, wedding, phones and as many other plans but most importantly, earn money while doing that.",
  },

  {
    question: "What is Group Saving?",
    answer1:
      "Group savings is a target set by a group of users on the app to meet/smash common financial goals. The spice here is the ‘ginger’ you get from being a part of a successful team.",
  },

  {
    question: "How do I activate/create a Target/ Group Savings",
    answer1:
      "Visit our website or download our app to create an account. log in to your dashboard and scroll to the savings plan. select create a savings plan,  select the target and fill the necessary fields.",
    answer2:
      "There is a link to invite friends and family to join a group plan.",
  },

  {
    question: "Can I break my Target or Group Savings at anytime?",
    answer1: "No, you cannot break your target savings until its expiration",
  },
];

export const Interest = [
  {
    question: "Do I get interest on my savings with investYield?",
    answer1:
      "Yes you can choose to earn interest if you want, and you can also view the progress of your savings every day.",
  },

  {
    question: "How is my interest paid?",
    answer1:
      "Each time you log into the application, you can view your savings and the accrued interest as at that date. However, the interest amount on your plans will become available to you on the maturity of the plan.",

    answer2:
      "In the case of funds in your allocated expense purses, the interest will be credited into the purse at the end of every month.",
  },

  {
    question: "What if I don't want interest on my savings?",
    answer1:
      "You can choose the option of not adding interest on your savings plan.",
  },
];

export const Withdrawals = [
  {
    question: "When do I withdraw my savings?",
    answer1:
      "You can withdraw your savings after the target date has arrived. ",
    answer2:
      "Funds from matured savings plans and stash will be paid into your default purse. you can decide to create new purses or plans from there or simply take the money back into your bank account.",
  },

  {
    question: " What are investYield's free withdrawal dates?",
    answer1:
      "Free withdrawals are available on the myPurse feature, all day, every day. However, you will be unable to access funds in your savings plans until they mature and in the case of Stash, pay a small breakage fee.",
  },

  {
    question: "Can I choose any amount to withdraw per time?",
    answer1:
      "Yes, you can choose the amount to withdraw and continue to save the balance for a later date.",
  },

  {
    question: "How do I withdraw my savings?",
    answer1:
      "You can withdraw your savings by going to the “Account” page and you tap or click on the withdraw button. Select the Purse to withdraw from and make your withdrawal.",
  },

  {
    question: "What is the minimum/maximum amount I can withdraw?",
    answer1: "There is no maximum or minimum.",
  },

  {
    question: "Can I pause/cancel or edit any of my saving plans?",
    answer1:
      "You can edit your savings plans by accessing any of the plans and tapping or clicking on them but you cannot cancel a plan once it has been created. For special emergencies, please contact support at support@investyield.ng",
  },
];

export const FeesCharges = [
  {
    question: "What are the fees and charges I should know of?",
    answer1:
      "There are no fees or charges for using the application. It is free! ",
  },

  {
    question:
      "Are there any charges when I withdraw my savings before the maturity date?",
    answer1: "Savings Plans: you cannot break the plans before they mature",
    answer2:
      "Stash: there is a small breakage fee for breaking before maturity date",
    answer3: "myPurse: free withdrawals all day, every day!",
  },
];

export const Referrals = [
  {
    question: "How does Referral work?",
    answer1:
      "Users of the app are rewarded for introducing an active customer. A new user must have created a savings through any of the features to become an active user. ",
  },

  {
    question: "Who can refer?",
    answer1:
      "Anyone registered on investYield has access to a referral code and can refer another person.",
  },

  {
    question: "How can I check my referrals?",
    answer1:
      "You can check your referrals by going to “Account” and  selecting “Refer and Earn” ",
  },
];
