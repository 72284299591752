import React from "react";

const Index = () => {
  return (
    <>
      <main>
        <section className="">
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="">
                  <h3 className="text-blue">Terms and Conditions</h3>
                  <p className="text-blue mt-4">Effective Date: 01 July 2021</p>
                  <p className="font-sm">
                    InvestYield is an automated savings and investment platform
                    available online and through mobile applications.
                  </p>
                </div>
                <div className="mt-4">
                  <h5 className="text-blue">DEFINITIONS</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      These Terms of Use (these "Terms") represent an agreement
                      between you and Revenue Bull Technologies Limited
                      ("investYield") and contain the terms and conditions
                      governing your use of and access to our website at
                      https://investyield.ng and all affiliated websites owned
                      and operated by us (collectively, the "Website") and our
                      products, services, and applications (together with the
                      Website, the "Services"). "You" and "your" mean the person
                      who uses or accesses the Services. "We," "us," and "our"
                      mean investYield and its successors, affiliates, and
                      assignees. As used in these Terms, "investYield Account"
                      means the account you have with us for the Services.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">THE AGREEMENT</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      Your use of and access to the Services are subject at all
                      times to these Terms and our Privacy Policy. Please read
                      these Terms and our Privacy Policy carefully. By using or
                      accessing the Services, you represent that you have read
                      and understand these Terms and our Privacy Policy and you
                      agree to be bound by these Terms and our Privacy Policy.
                      If you do not agree to all the terms and conditions of
                      these Terms and our Privacy Policy, do not use or access
                      the Services. This Agreement, as well as the Privacy
                      Policy, applies to both Users and Clients. If you elect to
                      become a Client, you will be subject to these Terms of
                      Use, our Client Agreement, our Privacy Policy and any
                      additional terms to which you agree when you create and
                      fund a savings and or investment plan.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">OBLIGATIONS</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      To use the Services, you must:
                      <ul>
                        <li>
                          accept and agree to these Terms and our Privacy
                          Policy;
                        </li>
                        <li>
                          register with us to create an account ("User
                          Account");
                        </li>
                        <li>be at least 18 years of age;</li>
                        <li>
                          have a Bank Account with a Nigerian financial
                          institution; and
                        </li>
                        <li>
                          provide all information requested by us, such as your
                          name, email address, mobile device number, online
                          credentials for your Bank Account, and such other
                          information as we may request from time to time
                          (collectively, "User Information").
                        </li>
                      </ul>
                      You will choose a user identifier, which may be your email
                      address or another term, as well as a password. You may
                      also provide personal information, including, but not
                      limited to, your name. You are responsible for
                      safeguarding your password and other User Account
                      information. You agree not to disclose your password to
                      any third party and you will notify us immediately if your
                      password is lost or stolen or if you suspect any
                      unauthorized use of your User Account. You represent and
                      warrant that all User Information you provide us from time
                      to time is truthful, accurate, current, and complete, and
                      you agree not to misrepresent your identity or your User
                      Information. You agree to promptly notify us of changes to
                      your User Information by updating your investYield
                      Account; provided, however, that you must notify us at
                      least three Business Days before any changes to your Bank
                      Account information, including, but not limited to, the
                      closure of your Bank Account for any reason by calling
                      +234 818 024 8941 or emailing support@investyield.ng or by
                      updating your investYield Account via the Website. If we
                      approve your registration, you will be authorized to use
                      the Services, subject to these Terms. Providing false or
                      inaccurate information, or using the Website or Services
                      to further fraud or unlawful activity is grounds for
                      immediate termination of this Agreement.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">LICENSE TO USE WEBSITE</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      The Company may provide You with certain information as a
                      result of your use of the Website or Services. Such
                      information may include, but is not limited to,
                      documentation, data, or information developed by the
                      Company, and other materials which may assist in your use
                      of the Website or Services ("Company Materials"). Subject
                      to this Agreement, the Company grants you a non-exclusive,
                      limited, non-transferable and revocable license to use the
                      Company Materials solely in connection with your use of
                      the Website and Services. The Company Materials may not be
                      used for any other purpose, and this license terminates
                      upon your cessation of use of the Website or Services or
                      at the termination of this Agreement.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">INTELLECTUAL PROPERTY</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      You agree that the Website and all Services provided by
                      the Company are the property of the Company, including all
                      copyrights, trademarks, trade secrets, patents, and other
                      intellectual property ("Company IP"). You agree that the
                      Company owns all right, title and interest in and to the
                      Company IP and that you will not use the Company IP for
                      any unlawful or infringing purpose. You agree not to (and
                      not to allow any third party to) reproduce or distribute
                      the Company IP in any way, including electronically or via
                      registration of any new trademarks, trade names, service
                      marks or Uniform Resource Locators (URLs).
                    </li>
                    <li className="font-sm">
                      InvestYield does not grant you any licenses, express or
                      implied, to its intellectual property or the intellectual
                      property of its licensors, except as expressly stated in
                      these Terms. We and our third-party licensors retain all
                      rights, title, and interest in and to the Services,
                      Content, and any associated patents, trademarks,
                      copyrights, mask work rights, trade secrets, and other
                      intellectual property rights.
                    </li>
                    <li className="font-sm">
                      The Services may permit you to submit content, send emails
                      and other communications, and provide other information
                      for publication or distribution to third parties
                      (collectively, "User Content"). Any User Content must not
                      be illegal, threatening, obscene, racist, defamatory,
                      libelous, pornographic, infringing of intellectual
                      property rights, promoting of illegal activity or harm to
                      groups and/or individuals, invasive of privacy, purposely
                      false or otherwise injurious to third parties, or
                      objectionable and must not consist of or contain software,
                      computer viruses, commercial solicitation, political
                      campaigning, chain letters, mass mailings, or any form of
                      "spam." If you submit User Content, and unless we indicate
                      otherwise, you grant us a nonexclusive, perpetual,
                      royalty-free, irrevocable, and fully sublicensable
                      (through multiple tiers) right to use, modify, reproduce,
                      adapt, translate, publish, create derivative works from,
                      distribute, display, and otherwise exploit such User
                      Content throughout the world in any media. We take no
                      responsibility and assume no liability for any User{" "}
                    </li>
                    <li className="font-sm">
                      To the extent permitted by applicable law, we may, in our
                      sole discretion and without liability to you, terminate
                      (or suspend access to) your use of the Services, Content,
                      or your InvestYield Account for any reason, including, but
                      not limited to, your breach of these Intellectual Property
                      Terms.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">ACCEPTABLE USE</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      You agree not to use the Website or Services for any
                      unlawful purpose or any purpose prohibited under this
                      clause. You agree not to use the Website or Services in
                      any way that could damage the Website, Services, or
                      general business of the Company. <br /> You further agree
                      not to use the Website or Services:
                      <ul>
                        <li>
                          To harass, abuse, or threaten others or otherwise
                          violate any person's legal rights;
                        </li>
                        <li>
                          To violate any intellectual property rights of the
                          Company or any third party;
                        </li>
                        <li>
                          To upload or otherwise disseminate any computer
                          viruses or other software that may damage the property
                          of another;
                        </li>
                        <li>To perpetrate any fraud;</li>
                        <li>
                          To engage in or create any unlawful gambling,
                          sweepstakes, or pyramid scheme
                        </li>
                        <li>
                          To publish or distribute any obscene or defamatory
                          material;
                        </li>
                        <li>
                          To publish or distribute any material that incites
                          violence, hate, or discrimination towards any group;
                        </li>
                        <li>To unlawfully gather information about others.</li>
                      </ul>
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">
                    AFFILIATE MARKETING & ADVERTISING
                  </h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      The Company, through the Website and Services, may engage
                      in affiliate marketing whereby the Company receives a
                      commission on or percentage of the sale of goods or
                      services on or through the Website. The Company may also
                      accept advertising and sponsorships from commercial
                      businesses or receive other forms of advertising
                      compensation.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">THIRD-PARTY WEBSITES</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      Our Services may contain links or connections to
                      third-party websites or services that are not owned or
                      operated by us or our third-party service providers or
                      licensors. We provide such links and connections for your
                      reference only. We do not control such third-party
                      websites or services and are not responsible for their
                      availability or content. Our inclusion of such links and
                      connections does not imply our endorsement of such
                      third-party websites or services or any association with
                      their owners or operators. We assume no liability
                      whatsoever for any such third-party websites or services
                      or any content, features, products, or practices of such
                      third-party websites or services. Your access and use of
                      such third-party websites and services is subject to
                      applicable third-party terms and conditions and privacy
                      policies. We encourage you to read the terms and
                      conditions and privacy policy of each third-party website
                      or service that you visit or utilize.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">PRIVACY INFORMATION</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      InvestYield takes the privacy of its users very seriously.
                      You understand that by using the Services, you consent to
                      the collection, use, storage, and disclosure of your
                      information as outlined in these Terms and our Privacy
                      Policy. Through your Use of the Website and Services, you
                      may provide us with certain information. By using the
                      Website or the Services, you authorize the Company to use
                      the information you have provided to us.
                      <br />
                      <br />
                      <dl>
                        <dt>Information We May Collect or Receive</dt>
                        <dd>
                          When you register for an account, you provide us with
                          a valid email address and may provide us with
                          additional information, such as your name or billing
                          information. Depending on how you use our Website or
                          Services, we may also receive information from
                          external applications that you use to access our
                          Website, or we may receive information through various
                          web technologies, such as cookies, log files, clear
                          gifs, web beacons or others.
                        </dd>
                        <br />
                        <dt>How We Use Information</dt>
                        <dd>
                          We use the information gathered from you to ensure
                          Your continued good experience on our website,
                          including through email communication. We may also
                          track certain aspects of the passive information
                          received to improve our marketing and analytics, and
                          for this, we may work with third-party providers.
                          <br />
                          For our compliance purposes and to provide the
                          Services to you, you hereby authorize us to, directly
                          or through a third-party, obtain, verify, and record
                          information and documentation that helps us verify
                          your identity and Bank Account information. When you
                          register for the Services and from time to time
                          thereafter, we may require you to provide and/or
                          confirm information and documentation that will allow
                          us to identify you, such as: A copy of your
                          government-issued photo ID, such as a passport or
                          driver’s license; A copy of a utility bill, bank
                          statement, affidavit, or other bills, dated within
                          three months of our request, with your name and street
                          address on it; and Such other information and
                          documentation that we may require from time to time.{" "}
                          <br />
                          By using the Services and providing User Information
                          to us, you automatically authorize us to obtain,
                          directly or indirectly through our third-party service
                          providers and without any time limit or the
                          requirement to pay any fees, information about you and
                          your Bank Account from the financial institution
                          holding your Bank Account and other third-party
                          websites and databases as necessary to provide the
                          Services to you. For purposes of such authorization,
                          you hereby grant InvestYield and our third-party
                          service providers a limited power of attorney, and you
                          hereby appoint InvestYield and our third-party service
                          providers as your true and lawful attorney-in-fact and
                          agent, with the full power of substitution and
                          re-substitution, for you and in your name, place, and
                          stead, in all capacities, to access third-party
                          websites, servers, and documents; retrieve
                          information; and use your User Information, all as
                          described above, with the full power and authority to
                          do and perform each act and thing requisite and
                          necessary to be done in connection with such
                          activities, as fully to all intents and purposes as
                          you might or could do in person.
                          <br />
                          The information you provide us is subject to our
                          Privacy Policy
                        </dd>
                        <br />
                        <dt>How You Can Protect Your Information</dt>
                        <dd>
                          If you would like to disable our access to any passive
                          information we receive from the use of various
                          technologies, you may choose to disable cookies in
                          your web browser. Please be aware that the Company
                          will still receive information about you that you have
                          provided, such as your email address.
                        </dd>
                      </dl>
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">ASSUMPTION OF RISK</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      InvestYield is an automated savings and investment
                      platform available online and through website and mobile
                      applications. InvestYield is not a financial adviser, and
                      the Services are not intended to provide financial advice.
                      You acknowledge and agree that any information posted on
                      our Website is not intended to be financial advice, and no
                      fiduciary relationship has been created between you and
                      the Company. Your financial situation is unique. We are
                      not responsible for ensuring your debit card has
                      sufficient funds for your needs, purposes, or
                      transactions. We do not make any representations,
                      warranties, or guarantees of any kind that the Services
                      are appropriate for you. Before using the Services, you
                      should consider obtaining additional information and
                      advice from a financial adviser. You further agree that
                      your use of any of our services vis our Website or mobile
                      applications is at your own risk. The Company does not
                      assume responsibility or liability for any advice or other
                      information obtained through your use of any of its
                      services.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">REVERSE ENGINEERING & SECURITY</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      You agree not to undertake any of the following actions:
                      <ul>
                        <li>
                          reverse engineer, or attempt to reverse engineer or
                          disassemble any code or software from or on the
                          Website or Services;
                        </li>
                        <li>
                          jeopardize the security of your InvestYield Account or
                          any other person’s InvestYield Account (such as
                          allowing someone else to use your username and
                          password to access the Services);
                        </li>
                        <li>
                          violate the security of the Website or Services
                          through any unauthorized access, circumvention of
                          encryption or other security tools, data mining or
                          interference to any host, user or network;
                        </li>
                        <li>
                          attempt, in any manner, to obtain the username,
                          password, account, or other security information from
                          any other user of the Services;{" "}
                        </li>
                        <li>
                          violate the security of any computer network or crack
                          any passwords or security encryption codes;
                        </li>
                        <li>
                          use any robot, spider, scraper, or other automatic or
                          manual device, process, or means to access the
                          Services or copy any Content, except as expressly
                          authorized by us; or
                        </li>
                        <li>
                          reverse engineer, decompile, disassemble, or otherwise
                          seek to obtain the source code of the Services or
                          Content.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">INDEMNIFICATION</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      At our request, you agree to defend, indemnify, and hold
                      harmless InvestYield, its affiliates, and its and their
                      respective employees, officers, directors, agents, and
                      third-party service providers from and against any claims,
                      suits, liabilities, damages (actual and consequential),
                      losses, fines, penalties, costs, and expenses (including
                      reasonable attorneys’ fees) arising from or in any way
                      related to any third-party claims relating to your use of
                      the Services, violation of these Terms, applicable law or
                      any third-party rights, or your fraud or willful
                      misconduct. Such indemnified parties reserve the right to
                      assume the exclusive defense and control of any matter
                      subject to indemnification by you, in which event you will
                      cooperate in asserting any available defenses. You agree
                      that the Company shall be able to select its legal counsel
                      and may participate in its defense if the Company wishes.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">MODIFICATION & VARIATION</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      The Company may, from time to time and at any time without
                      notice to you, subject to applicable law, modify this
                      Agreement. You agree that the Company has the right to
                      modify this Agreement or revise anything contained herein.
                      You further agree that all modifications to this Agreement
                      are in full force and effect immediately upon posting on
                      the Website and that modifications or variations will
                      replace any prior version of this Agreement unless prior
                      versions are specifically referred to or incorporated into
                      the latest modification or variation of this Agreement.
                      <ol>
                        <li>
                          To the extent any part or sub-part of this Agreement
                          is held ineffective or invalid by any court of law,
                          you agree that the prior, effective version of this
                          Agreement shall be considered enforceable and valid to
                          the fullest extent.
                        </li>
                        <li>
                          You agree to routinely monitor this Agreement and
                          refer to the Effective Date posted at the top of this
                          Agreement to note modifications or variations. You
                          further agree to clear your cache when doing so to
                          avoid accessing a prior version of this Agreement. You
                          agree that your continued use of the Website and our
                          products, services, and applications in whatever form
                          after any modifications to this Agreement is a
                          manifestation of your continued assent to this
                          Agreement.
                        </li>
                        <li>
                          If you fail to monitor any modifications to or
                          variations of this Agreement, you agree that such
                          failure shall be considered an affirmative waiver of
                          your right to review the modified Agreement.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">ENTIRE AGREEMENT</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      This Agreement constitutes the entire understanding
                      between the Parties concerning any use of this Website and
                      our products, services, and applications. This Agreement
                      supersedes and replaces all prior or contemporaneous
                      agreements or understandings, written or oral, regarding
                      the use of this Website and our products, services, and
                      applications.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">SERVICE INTERRUPTIONS</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      The Company may need to interrupt your access to the
                      Website and our products, services, and applications to
                      perform maintenance or emergency services on a scheduled
                      or unscheduled basis. You agree that your access to the
                      Website and our products, services, and applications may
                      be affected by unanticipated or unscheduled downtime, for
                      any reason, but that the Company shall have no liability
                      for any damage or loss caused as a result of such
                      downtime.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">NO WARRANTIES</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW AND
                      EXCEPT AS OTHERWISE EXPRESSLY PROVIDED IN THESE TERMS, YOU
                      EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE
                      SERVICES AND ALL INFORMATION, PRODUCTS, AND OTHER CONTENT
                      (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR
                      ACCESSIBLE FROM THE SERVICES IS AT YOUR SOLE RISK. THE
                      SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
                      BASIS WITHOUT ANY WARRANTY OF ANY KIND. TO THE MAXIMUM
                      EXTENT PERMITTED BY APPLICABLE LAW, WE AND OUR THIRD-PARTY
                      SERVICE PROVIDERS EXPRESSLY DISCLAIM ANY AND ALL
                      CONDITIONS, REPRESENTATIONS, AND WARRANTIES OF ANY KIND AS
                      TO THE SERVICES AND ALL INFORMATION, PRODUCTS, AND OTHER
                      CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR
                      ACCESSIBLE FROM THE SERVICES, WHETHER EXPRESS, IMPLIED,
                      STATUTORY, OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO,
                      ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
                      PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                    </li>
                    <li className="font-sm">
                      WITHOUT LIMITING THE FOREGOING, WE AND OUR THIRD-PARTY
                      SERVICE PROVIDERS MAKE NO WARRANTY THAT (A) THE SERVICES
                      WILL MEET YOUR REQUIREMENTS, (B) THE SERVICES WILL BE
                      UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (C) THE
                      RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES
                      WILL BE ACCURATE OR RELIABLE, (D) THE QUALITY OF ANY
                      PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL
                      PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICES WILL
                      MEET YOUR EXPECTATIONS, OR (E) ANY ERRORS IN THE SERVICES
                      WILL BE CORRECTED. NO ORAL OR WRITTEN INFORMATION OR
                      ADVICE OBTAINED BY YOU FROM US OR OUR THIRD-PARTY SERVICE
                      PROVIDERS THROUGH OR FROM THE SERVICES WILL CREATE ANY
                      WARRANTY NOT EXPRESSLY STATED IN THESE TERMS.
                    </li>
                    <li className="font-sm">
                      ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
                      SERVICES IS DONE AT YOUR DISCRETION AND RISK, AND YOU ARE
                      SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM
                      OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH
                      MATERIAL. YOU AGREE THAT ANY DAMAGE THAT MAY OCCUR TO YOU,
                      THROUGH YOUR COMPUTER SYSTEM, OR AS A RESULT OF LOSS OF
                      YOUR DATA FROM YOUR USE OF THE WEBSITE OR SERVICES IS YOUR
                      SOLE RESPONSIBILITY AND THAT THE COMPANY IS NOT LIABLE FOR
                      ANY SUCH DAMAGE OR LOSS.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">LIMITATION OF LIABILITY</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU
                      AGREE THAT InvestYield, ITS AFFILIATES, AND ITS AND THEIR
                      RESPECTIVE EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, AND
                      THIRD-PARTY SERVICE PROVIDERS WILL NOT BE LIABLE TO YOU OR
                      ANY THIRD PARTY FOR (A) THE PERFORMANCE OF THE SERVICES OR
                      THE INABILITY TO USE THE SERVICES; (B) ANY INDIRECT,
                      INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY
                      DAMAGES, WHETHER BASED IN CONTRACT, TORT, OR OTHERWISE,
                      INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
                      PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES,
                      EVEN IF SUCH PERSONS HAVE BEEN ADVISED OF THE POSSIBILITY
                      OF SUCH DAMAGES, WHICH ARISE OUT OF OR ARE IN ANY WAY
                      CONNECTED WITH THESE TERMS, THE SERVICES, OR CONTENT; (C)
                      ANY AMOUNT, IN THE AGGREGATE, ABOVE THE GREATER OF THE
                      AMOUNT YOU HAVE SAVED ON THE PLATFORM; (D) ANY EVENT
                      BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW
                      THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE
                      ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">GENERAL PROVISIONS:</h5>
                  <div className="small-red-line mb-4"></div>
                  <h5 className="text-black">LANGUAGE</h5>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      All communications made or notices given under this
                      Agreement shall be in the English language.
                    </li>
                  </ol>
                  <h5 className="text-black">
                    JURISDICTION, VENUE & CHOICE OF LAW
                  </h5>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      Through Your use of the Website and our products,
                      services, and applications, you agree that the laws of the
                      Federal Republic of Nigeria shall govern any matter or
                      dispute relating to or arising out of this Agreement, as
                      well as any dispute of any kind that may arise between you
                      and the Company, except its conflict of law provisions. In
                      case any litigation specifically permitted under this
                      Agreement is initiated, the Parties agree to submit to the
                      personal jurisdiction of the state and federal courts of
                      Nigeria. The Parties agree that this choice of law, venue,
                      and jurisdiction provision is not permissive, but rather
                      mandatory. You hereby waive the right to any objection of
                      venue, including assertion of the doctrine of forum
                      non-conveniens or similar doctrine.
                    </li>
                  </ol>
                  <h5 className="text-black">ARBITRATION</h5>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      In case of a dispute between the Parties relating to or
                      arising out of this Agreement, the Parties shall first
                      attempt to resolve the dispute personally and in good
                      faith. If these personal resolution attempts fail, the
                      Parties shall then submit the dispute to binding
                      arbitration. The arbitration will take place in the
                      federal judicial district of your residence. The
                      arbitration will be subject to the Federal Arbitration Act
                      and not any state arbitration law. The arbitration will be
                      conducted before one commercial arbitrator from the
                      Nigeria Arbitration and Conciliation Act with substantial
                      experience in resolving commercial contract disputes. As
                      modified by these Terms, and unless otherwise agreed upon
                      by the parties in writing, the arbitration will be
                      governed by the Nigeria Arbitration and Conciliation Act’s
                      Commercial Arbitration Rules and, if the arbitrator deems
                      them applicable, the Supplementary Procedures for
                      Consumer-Related Disputes (collectively, the "Rules and
                      Procedures").
                    </li>
                    <li className="font-sm">
                      You should review this provision carefully. To the extent
                      permitted by applicable law, you are GIVING UP YOUR RIGHT
                      TO GO TO COURT to assert or defend your rights EXCEPT for
                      matters that you file in small claims court in the state
                      or municipality of your residence within the
                      jurisdictional limits of the small claims court and as
                      long as such matter is only pending in that court.
                      Notwithstanding this agreement to arbitrate, either party
                      may seek emergency equitable relief in federal court if it
                      has jurisdiction or, if it does not, in a state court
                      located in the federal judicial district of your residence
                      to maintain the status quo pending arbitration, and each
                      party hereby agrees to submit to the exclusive personal
                      jurisdiction of the courts located within the federal
                      judicial district of your residence for such purpose. A
                      request for interim measures will not be deemed a waiver
                      of the obligation to arbitrate.
                    </li>
                    <li className="font-sm">
                      Your rights will be determined by a NEUTRAL ARBITRATOR and
                      NOT a judge or jury. You are entitled to a FAIR HEARING,
                      BUT the arbitration procedures may be SIMPLER AND MORE
                      LIMITED THAN RULES APPLICABLE IN COURT. Arbitrators’
                      decisions are as enforceable as any court order and are
                      subject to VERY LIMITED REVIEW BY A COURT.
                    </li>
                    <li className="font-sm">
                      You and we must abide by the following rules: (A) ANY
                      CLAIMS BROUGHT BY YOU OR US MUST BE BROUGHT IN THE PARTY’S
                      INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
                      MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
                      PROCEEDING; (B) THE ARBITRATOR MAY NOT CONSOLIDATE MORE
                      THAN ONE PERSON’S CLAIMS, MAY NOT OTHERWISE PRESIDE OVER
                      ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING, AND MAY
                      NOT AWARD CLASS-WIDE RELIEF; (c) in the event that you are
                      able to demonstrate that the costs of arbitration will be
                      prohibitive as compared to the costs of litigation, we
                      will pay as much of your filing and hearing fees in
                      connection with the arbitration as the arbitrator deems
                      necessary to prevent the arbitration from being
                      cost-prohibitive as compared to the cost of litigation,
                      (d) we also reserve the right, in our sole and exclusive
                      discretion, to assume responsibility for any or all of the
                      costs of the arbitration; (e) the arbitrator will honor
                      claims of privilege and privacy recognized at law; (f) the
                      arbitration will be confidential, and neither you nor we
                      may disclose the existence, content, or results of any
                      arbitration, except as may be required by applicable law
                      or for purposes of enforcement of the arbitration award;
                      (g) subject to the limitation of liability provisions of
                      these Terms, the arbitrator may award any individual
                      relief or individual remedies that are expressly permitted
                      by applicable law; and (h) each party will pay its own
                      attorneys’ fees and expenses, unless there is a statutory
                      provision that requires the prevailing party to be paid
                      its fees and litigation expenses and the arbitrator awards
                      such attorneys’ fees and expenses to the prevailing party,
                      and, in such instance, the fees and costs awarded will be
                      determined by the applicable law.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">ASSIGNMENT</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      This Agreement, or the rights granted hereunder, may not
                      be assigned, sold, leased or otherwise transferred in
                      whole or part by you. Should this Agreement, or the rights
                      granted hereunder, be assigned, sold, leased or otherwise
                      transferred by the Company, the rights and liabilities of
                      the Company will bind and inure to any assignees,
                      administrators, successors, and executors.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">TERMINATION</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      The Company specifically reserves the right to terminate
                      this Agreement if you violate any of the terms outlined
                      herein, including, but not limited to, violating the
                      intellectual property rights of the Company or a third
                      party, failing to comply with applicable laws or other
                      legal obligations, and/or publishing or distributing
                      illegal material. If you have registered for an account
                      with us, you may also terminate this Agreement at any time
                      by contacting us and requesting termination at
                      support@investyield.ng or +234 818 024 8941 and providing
                      sufficient information for us to verify your identity.
                      Notwithstanding the foregoing, if there are any pending
                      transactions relating to your investYield Account when we
                      receive your termination notice, we will close your
                      InvestYield Account promptly after such transactions are
                      completed. Your termination of these Terms will not affect
                      any of our rights or your obligations arising under these
                      Terms before termination. Upon the closure of your
                      InvestYield Account, we will transfer the funds in your
                      InvestYield Account, if any, to your Bank Account.
                    </li>
                    <li className="font-sm">
                      If you do not use the Services for a certain period,
                      applicable law may require us to report the funds in your
                      InvestYield Account as unclaimed property. If this occurs,
                      we may try to locate you at the address shown in our
                      records. If we are unable to locate you, we may be
                      required to deliver any funds in your InvestYield Account
                      to the applicable state as unclaimed property. The
                      specified period to report and deliver funds to a state
                      varies by state but usually ranges between two and five
                      years.
                    </li>
                    <li className="font-sm">
                      At the termination of this Agreement, any provisions that
                      would be expected to survive termination by their nature
                      shall remain in full force and effect.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">SEVERABILITY</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      If any part or sub-part of this Agreement is held invalid
                      or unenforceable by a court of law or competent
                      arbitrator, the remaining parts and sub-parts will be
                      enforced to the maximum extent possible. In such
                      condition, the remainder of this Agreement shall continue
                      in full force.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">NO WAIVER</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      If We fail to enforce any provision of this Agreement,
                      this shall not constitute a waiver of any future
                      enforcement of that provision or any other provision.
                      Waiver of any part or sub-part of this Agreement will not
                      constitute a waiver of any other part or sub-part.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">HEADINGS FOR CONVENIENCE ONLY</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      Headings of parts and sub-parts under this Agreement are
                      for convenience and organization, only. Headings shall not
                      affect the meaning of any provisions of this Agreement.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">
                    NO AGENCY, PARTNERSHIP OR JOINT VENTURE
                  </h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      No agency, partnership, or joint venture has been created
                      between the Parties as a result of this Agreement. No
                      Party has any authority to bind the other to third
                      parties. Notwithstanding the foregoing, you agree that our
                      third-party services providers are third-party
                      beneficiaries of the applicable provisions of these Terms,
                      with all rights to enforce such provisions as if such
                      service providers were a party to these Terms.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">FORCE MAJEURE</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      The Company is not liable for any failure to perform due
                      to causes beyond its reasonable control including, but not
                      limited to, acts of God, acts of civil authorities, acts
                      of military authorities, riots, embargoes, acts of nature
                      and natural disasters, and other acts which may be due to
                      unforeseen circumstances.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">ELECTRONIC COMMUNICATIONS </h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      All forms of communications are permitted to both Parties
                      under this Agreement, including e-mail, SMS or fax. For
                      any questions or concerns, please email us at the
                      following support@investyield.ng or +234 818 024 8941
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">MISCELLANEOUS</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      You will be responsible for paying, withholding, filing,
                      and reporting all taxes, duties, and other governmental
                      assessments associated with your activity in connection
                      with the Services, provided that we may, in our sole
                      discretion, do any of the foregoing on your behalf or for
                      ourselves. The failure of either you or us to exercise, in
                      any way, any right herein will not be deemed a waiver of
                      any further rights hereunder. If any provision of these
                      Terms is found to be unenforceable or invalid, that
                      provision will be limited or eliminated, to the minimum
                      extent necessary, so that these Terms will otherwise
                      remain in full force and effect and enforceable. These
                      Terms, together with our Privacy Policy, constitute the
                      entire and sole agreement between you and us concerning
                      the Services and supersedes all prior understandings,
                      arrangements, or agreements, whether written or oral,
                      regarding the Services. You hereby acknowledge and agree
                      that you are not an employee, agent, partner, or joint
                      venture of InvestYield, and you do not have any authority
                      of any kind to bind InvestYield in any respect whatsoever
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Index;
